.header {
  width: 300px;
  height: 75px;
  display: flex;
  align-self: center;
  background-image: url("./images/DV-design2.png");
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  background-size: cover;
  justify-self: center;
  padding-top: 10%;
}
.mission-statement {
  position: absolute;
  top: 4%;
  left: 45%;
}
.header-wrapper {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}

.Homepage {
  width: 100vw;
  height: 100vh;
  background-image: url("./images/2.png");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.Homepage-mobile {
  width: 100vw;
  height: 100vh;
  background-image: url("./images/background.png");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.sign-in-element-typewriter {
  width: 40vw;
  flex-direction: column;
}

.dual-panel-wrapper {
  width: 100vw;
  height: 100vh;
}
.panel-one {
  width: 50vw;
  height: 100vh;
  display: flex;
  position: relative;
}
.panel-one-mobile {
  width: 100%;
  height: 100vh;
  display: flex;
  /* position: relative; */
}
.panel-two {
  margin: auto;
  width: 50vw;
  height: 100vh;
  opacity: 80%;
}

.signin-wrapper {
  display: inline-block;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  display: flex;
  align-content: center;
}

.signin-h1 {
  /* border: 3px solid red; */
  font-size: x-large;
  height: 30%;
  width: 100%;
  padding-top: 10%;
  text-align: center;
}
.signin-p {
  margin-top: 2%;
  font-size: large;
  height: 10%;
  width: 100%;
  text-align: center;
}
.signin-body {
  /* border: 3px solid red; */
  width: 100%;
  height: 15%;
  text-align: center;
  padding-top: 3%;
}
.sign-in-element-email {
  /* border: 3px solid red; */
  height: 20%;
  width: 60%;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 10%;
}
.signin-start-registry {
  position: absolute;
  width: 100%;
  align-items: center;
  bottom: 5%;
}
.loader {
  display: flex;
  position: absolute;
  bottom: 25%;
  right: 50%;
}

.panel-one-questions {
  display: flex;
  /* border: 3px solid red; */
  height: 30vh;
  width: 100%;
  position: relative;
  top: 10%;
  word-wrap: break-word;
  justify-self: center;
  /* text-align: left; */
  padding-left: 10%;
  /* right: 10%; */
  /* width: calc(100% - 300px); */
}

.question-container {
  /* border: 3px solid red; */
  display: flex;
  /* place-content: center; */
  /* width: 90%; */
  /* height: 80%; */
  /* min-width: 300px; */
}
.questions-wrapper {
  width: 100%;
  /* border: 3px solid red; */
}

.panel-one-answers {
  /* border: 3px solid red; */
  display: grid;
  position: absolute;
  align-items: center;
  top: 35%;
  height: 40vh;
  width: 80%;
  left: 10%;
  justify-content: center;
}
.panel-one-buttons {
  display: grid;
  position: absolute;
  width: 80%;
  justify-self: center;
  bottom: 10%;
  left: 10%;
}
.selectRegistry-header {
  /* border: 3px solid red; */
  /* height: 10%; */
  width: 80%;
  align-self: center;
}
.selectRegistry-buttons-wrapper {
  /* border: 3px solid red; */
  /* position: absolute; */
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  height: 70vh;
  padding-top: 0%;
}

.selectButtons-container {
  display: flex;
  justify-content: space-between;
  width: 50%;
  float: left;
}

.selectButtons {
  position: absolute;
  flex-wrap: wrap;
  flex-direction: row;
  display: flex;
  width: 100%;
  height: 35%;
  justify-content: space-evenly;
  padding: 5%;
}

.answer-element-continue {
  /* border: 3px solid red; */
  /* display: flex; */
  position: absolute;
  width: 80%;
  /* margin: auto; */
  justify-self: center;
  align-self: center;
  top: -7%;
  left: 70%;
}

.boolean-wrapper {
  /* border: 3px solid red; */
  display: flex;
  position: relative;
  height: 20vh;
  width: 40vw;
  flex-wrap: wrap;
  margin-top: 15%;
  /* align-content: center;
  justify-content: space-between; */
  /* left: 1%; */
}

.boolean-button {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 5%;
  /* align-content: center; */
}

.wrapper-block {
  /* flex-grow: 1; */
  height: 95vh;
  width: 95vw;
  flex-direction: column;
  /* margin: auto;
  justify-content: flex-end; */
}

.wrapper-registry-block {
  /* border: 3px solid red; */
  flex-direction: row;
  height: 97vh;
  width: 100%;
  margin-left: 5%;
}

.registry-block {
  /* border: 3px solid red; */
  height: 100%;
  width: 60%;
  flex-direction: column;
  /* justify-content: center;
  align-items: center; */
}

.stepper-block {
  border: 2px solid rgb(157, 150, 139);
  height: 15%;
  width: 50%;
  justify-content: center;
}

.question-block {
  height: 40%;
  width: 50%;
  text-align: center;
  border: 2px solid rgb(157, 150, 139);
  border: 2px solid rgb(227, 232, 239);
  /* margin: auto; */
  /* background: rgba(250, 251, 253, 253.05);
  box-shadow: 0px 1px 2px rgba(1, 0, 0, .51), 1px 2px 0px 2px rgba(0, 0, 0, 0.04); */
}
.genderSelect {
  display: flex;
  /* border: 3px solid red; */
  flex-wrap: wrap;
  width: 20vw;
  height: 40vh;
  /* justify-content: space-around; */
  align-content: space-around;
  margin-top: 15%;
}
.genderBtn {
  margin: 1%;
}
.answer-block {
  /* border: 3px solid red; */
  display: grid;
  height: 100%;
  width: 100%;
  /* padding-top: 4rem; */
}

.summary-block {
  width: 25%;
  height: 100%;
  border-radius: 15px;
  box-shadow: 0px 1px 2px rgba(1, 0, 0, 0.51),
    1px 2px 0px 2px rgba(0, 0, 0, 0.04);
}
.submissionComplete-wrapper {
  margin-top: 5%;
}

.additional-info-block {
  width: 25%;
  height: 100%;
  flex-direction: column;
}

.user-block {
  height: 45%;
  width: 100%;
  border-radius: 15px;
  box-shadow: 0px 1px 2px rgba(1, 0, 0, 0.51),
    1px 2px 0px 2px rgba(0, 0, 0, 0.04);
  justify-content: space-around;
  margin: auto;
}

.border {
  border: 3px solid red;
}

.backBtn {
  width: 100%;
  /* border: 3px solid red; */
}

.btn-select-text {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  white-space: pre-line;
  padding-top: 15%;
  /* border: 3px solid red; */
}

.index-summary-wrapper {
  color: rgb(147, 154, 236);
  /* font-weight: 600; */
  display: flex;
  /* border: 3px solid red; */
}

.index-summary {
  color: rgb(147, 154, 236);
  font-weight: 500;
  display: flex;
  width: 4.5rem;
}

.index-summary-component {
  color: rgb(147, 154, 236);
  font-weight: 500;
  display: flex;
  width: 18px;
}

.btn-registry-select-wrapper {
  display: flex;
  flex-wrap: nowrap;
  width: 45%;
}
