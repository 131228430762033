@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  height: 100vh;
}

html,
body {
  overflow-x: hidden;
  overflow-y: hidden;
}

element {
  text-align: center;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 40px;
  padding: 2%;
}

li {
  font-size: 16px;
  font-weight: 500;
  color: rgb(47, 55, 71);
}
h1 {
  overflow-wrap: normal;
}
h3 {
  color: rgb(147, 154, 236);
  font-weight: 500;
}

h4 {
  color: rgb(147, 154, 236);
}

h5 {
  font-size: 0.75rem;
  color: rgb(147, 154, 236);
}

h2 {
  font-weight: 700;
  color: black;
  font-size: large;
}

p {
  color: black;
  font-weight: 500;
}

input,
select,
textarea {
  color: black;
}

/* SigninButton */
.btn {
  background-color: #fff !important;
  display: inline-block;
  outline: 0;
  border: 0;
  cursor: pointer;
  transition: box-shadow 0.15s ease, transform 0.15s ease;
  will-change: box-shadow, transform;
  background: #fcfcfd;
  /* box-shadow: 0px 2px 4px rgb(45 35 66 / 40%),
    0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px #d6d6e7; */
  box-shadow: 2px 3px 3px 0px rgb(147, 154, 236);
  height: 48px;
  width: 100%;
  padding: 0 32px;
  font-size: 18px;
  border-radius: 6px;
  color: rgb(91, 100, 233);
  transition: box-shadow 0.15s ease, transform 0.15s ease;
}
.btn:hover {
  background: transparent !important;
  /* box-shadow: 0px 4px 8px rgb(45 35 66 / 40%),
    0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px #d6d6e7;
  transform: translateY(-2px); */
  backface-visibility: hidden;
  background: linear-gradient(20deg, var(--copartBlue), var(--copartBlue));
  box-shadow: rgba(0, 0, 0, 0.14) 0px 4px 6px -2px,
    rgba(0, 0, 0, 0.12) 0px 2px 4px 1px;
  transform: scale(1.02);
}
.btn:hover:not(:disabled) {
  backface-visibility: hidden;
  background: linear-gradient(20deg, var(--copartBlue), var(--copartBlue));
  box-shadow: rgba(0, 0, 0, 0.14) 0px 4px 6px -2px,
    rgba(0, 0, 0, 0.12) 0px 2px 4px 1px;
  transform: scale(1.02);
  /* box-shadow: 0px 4px 8px rgb(45 35 66 / 40%),
    0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px #d6d6e7;
  transform: translateY(-2px); */
}
.btn:active {
  /* box-shadow: inset 0px 3px 7px #d6d6e7; */
  transform: translateY(2px);
}
.btn:active:not(:disabled) {
  transform: scale(0.98);
}
.btn:focus {
  box-shadow: 0 0 0 2px #ffffff, 0 0 3px 5px #3a97f9;
  transform: translateY(2px);
}

/* RegistrySelectButtons */
.btn2 {
  display: inline-block;
  outline: 0;
  border: 0;
  cursor: pointer;
  transition: box-shadow 0.15s ease, transform 0.15s ease;
  will-change: box-shadow, transform;
  background: rgb(49, 51, 56);
  /* box-shadow: 0px 2px 4px rgb(45 35 66 / 40%),
    0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px #d6d6e7; */
  height: 48px;
  width: 100px;
  padding: 0 32px;
  font-size: 18px;
  margin: 5%;
  border-radius: 6px;
  color: #36395a;
  transition: box-shadow 0.15s ease, transform 0.15s ease;
  box-shadow: 2px 3px 3px 0px rgb(147, 154, 236);
}
.btn2:hover {
  background: transparent !important;
  box-shadow: 0px 4px 8px rgb(45 35 66 / 40%),
    0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px #d6d6e7;
  transform: translateY(-2px);
}

.btn2:hover:not(:disabled) {
  backface-visibility: hidden;
  background: linear-gradient(20deg, var(--copartBlue), var(--copartBlue));
  box-shadow: rgba(0, 0, 0, 0.14) 0px 4px 6px -2px,
    rgba(0, 0, 0, 0.12) 0px 2px 4px 1px;
  transform: scale(1.02);
  /* box-shadow: 0px 4px 8px rgb(45 35 66 / 40%),
    0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px #d6d6e7;
  transform: translateY(-2px); */
}

.btn2:active {
  /* box-shadow: inset 0px 3px 7px #d6d6e7; */
  transform: translateY(2px);
  background-color: #36395a;
  box-shadow: 0 0 0 2px #ffffff, 0 0 3px 5px #3a97f9;
  transform: translateY(2px);
}
.btn2:active:not(:disabled) {
  transform: scale(0.98);
}
.btn2:focus {
  box-shadow: 0 0 0 2px #ffffff, 0 0 3px 5px #3a97f9;
  transform: translateY(2px);
}

/* Next/Back Buttons */
.btn3 {
  background-color: #fff !important;
  padding: 10px 16px 9px;
  color: var(--white);
  border: 1px solid var(--copartBlue);
  border-radius: 2px;
  outline: none;
  font-weight: 400;
  width: 90%;
  transition: all 0.1s;
  cursor: pointer;
  display: block;
  /* max-width: 150px; */
  transform: translateZ(0);
  margin: 5%;
  box-shadow: 2px 3px 3px 0px rgb(147, 154, 236);
}
.btn3:hover {
  background: transparent !important;
  box-shadow: 0px 4px 8px rgb(45 35 66 / 40%),
    0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px #d6d6e7;
  transform: translateY(-2px);
}
.btn3:hover:not(:disabled) {
  backface-visibility: hidden;
  background: linear-gradient(20deg, var(--copartBlue), var(--copartBlue));
  box-shadow: rgba(0, 0, 0, 0.14) 0px 4px 6px -2px,
    rgba(0, 0, 0, 0.12) 0px 2px 4px 1px;
  transform: scale(1.02);
  /* box-shadow: 0px 4px 8px rgb(45 35 66 / 40%),
    0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px #d6d6e7;
  transform: translateY(-2px); */
}
.btn3:active {
  box-shadow: inset 0px 3px 7px #d6d6e7;
  background-color: #36395a;
  box-shadow: 0 0 0 2px #ffffff, 0 0 3px 5px #3a97f9;
  transform: translateY(2px);
}
.btn3:active:not(:disabled) {
  transform: scale(0.96);
}

.btn3:disabled {
  cursor: not-allowed;
  opacity: 0.4;
  filter: saturate(0.25);
  border: 0;
}

.btn3:focus {
  /* box-shadow: 0 0 0 2px #ffffff, 0 0 3px 5px #3a97f9; */
  transform: translateY(2px);
}

.btn-registry-select {
  /* background: linear-gradient(180deg, var(--white), var(--white)); */
  /* background-color: linear-gradient(20deg, white); */
  background-color: #fff !important;
  border: 3px solid #fff;
  padding: 10px 16px 9px;
  color: var(--white);
  border: 1px #fff;
  border-radius: 0px;
  outline: none;
  font-weight: 400;
  width: 20vw;
  height: 19vh !important;
  transition: all 0.8s;
  cursor: pointer;
  display: block;
  transform: translateZ(0);
  margin: 5%;
  box-shadow: 2px 3px 3px 0px rgb(147, 154, 236);
  display: block;
}

.btn-registry-select:hover {
  background: transparent !important;
  box-shadow: 0px 4px 8px rgb(45 35 66 / 40%),
    0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px #d6d6e7;
  transform: translateY(-2px);
}
.btn-registry-select:hover:not(:disabled) {
  backface-visibility: hidden;
  background: linear-gradient(20deg, var(--copartBlue), var(--copartBlue));
  /* box-shadow: 0 0 0 2px #ffffff, 0 0 3px 5px rgb(147, 154, 236); */
  transform: scale(1.02);
}
.btn-registry-select:active {
  text-decoration: none;
  box-shadow: inset 0px 3px 7px #d6d6e7;
  background-color: #36395a;
  box-shadow: 0 0 0 2px #ffffff, 0 0 3px 5px rgb(147, 154, 236);
  transform: translateY(2px);
  color: #fff;
}
.btn-registry-select:active:not(:disabled) {
  transform: scale(0.96);
  box-shadow: 0 0 0 2px #ffffff, 0 0 3px 5px rgb(147, 154, 236);
}

.btn-registry-select:disabled {
  cursor: not-allowed;
  opacity: 0.4;
  filter: saturate(0.25);
  border: 0;
  box-shadow: 0 0 0 2px #ffffff, 0 0 3px 5px rgb(147, 154, 236);
}

.btn-registry-select:focus {
  box-shadow: 0 0 0 2px #ffffff, 0 0 3px 5px rgb(147, 154, 236);
  transform: translateY(2px);
}

@media screen and (max-width: 568px) {
  .btn-registry-select {
    width: 42vw;
  }
  .btn {
    width: 20vw;
  }
  .h5 {
    font-size: 0.75rem;
  }
  h1 {
    font-size: 1.25rem;
    font-weight: 600;
    color: black;
  }
  .selectRegistry-buttons-wrapper {
    /* display: flex; */
    /* border: 3px solid red; */
    justify-content: center;
    height: 80vh;
  }
  .panel-one-questions {
    width: 100vw;
  }
}

@media screen and (min-width: 569px) {
  .btn-registry-select {
    width: 20vw;
  }
  .btn {
    width: 10vw;
  }
  h5 {
    font-size: 0.7rem;
  }
  h1 {
    font-size: 1.5rem;
    font-weight: 600;
    color: black;
  }
}

@media screen and (min-width: 769px) {
  .btn-registry-select {
    width: 20vw;
  }
  .btn {
    width: 10vw;
  }
  h5 {
    font-size: 1rem;
  }
  .selectRegistry-buttons-wrapper {
    justify-items: flex-start;
    padding-left: 5%;
    padding-top: 5%;
  }
}

@media screen and (max-width: 400px) {
  h1 {
    font-size: 1.25rem;
    font-weight: 500;
    color: black;
    overflow-wrap: break-word;
  }
}
